var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-table',{ref:"table",attrs:{"caption":_vm.tableCaption,"columns":_vm.columns,"actions":_vm.tableActions,"data":_vm.tickets,"loading":_vm.gettingTickets,"total-server-items":_vm.meta.total},on:{"update:page":_vm.handlePageUpdated,"refresh":function($event){return _vm.init({
            page: _vm.$route.query.page || 1
        })},"search":_vm.handleSearch},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"tw-flex tw-items-center tw-space-x-2"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.priority ? item.priority.color : null}},'v-icon',attrs,false),on),[_vm._v("fas fa-square")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.priority ? item.priority.title : 'Unknown priority'))])]),_c('span',[_vm._v(_vm._s(item.number))])],1)]}},{key:"item.title",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                        name: 'tickets.details',
                        params: {
                            ticketId: item.id,
                        }
                    }}},[_vm._v(_vm._s(item.title))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }